import Head from 'next/head';

export default function SEO(context: {
  description: string;
  title: string;
  siteName: string;
  cardType: string;
  image?: string;
  pathname: string;
}): JSX.Element {
  const languageList = [
    'en',
    'en-us',
    'en-gb',
    'zh',
    'zh-cn',
    'zh-hk',
    'zh-tw',
  ];
  const { description, title, siteName, cardType, image, pathname } = context;
  const defaultURL = `https://www.aimoon.com/en${
    pathname ? `/${pathname}` : ''
  }`;
  return (
    <Head>
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      {image ? <meta property="og:image" content={image} /> : null}
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:url" content={defaultURL} />
      <meta property="twitter:card" content={cardType} />
      <meta property="twitter:site" content={siteName} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      {image ? <meta property="twitter:image" content={image} /> : null}
      {languageList.map((item) => {
        let formatLang: string;
        if (item.split('-')[0] === 'en') {
          formatLang = 'en';
        } else {
          formatLang = 'zh-CN';
        }
        return (
          <link
            key={item}
            rel="alternate"
            href={`https://www.aimoon.com/${formatLang}${
              pathname ? `/${pathname}` : ''
            }`}
            hrefLang={item}
          />
        );
      })}
      <link rel="alternate" hrefLang="x-default" href={defaultURL} />
    </Head>
  );
}
