import { useState, useEffect } from 'react';
import Head from 'next/head';
import animationStyles from '@/styles/common.module.scss';
import styles from '@/styles/Download.module.scss';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetStaticProps } from 'next';
import classnames from 'classnames';
import Modal from 'react-modal';
import isMobileDevice from '../utils/func/isMobileDevice';
import trackLink from '../utils/func/trackLink';
import getOS from '../utils/func/getOS';
import { useRouter } from 'next/router';
import Link from 'next/link';
import SeoHead from '../components/Seo';

import LogoSVG from '../svg/logo.svg';
import TriangleDownSVG from '../svg/triangle-down.svg';
import TriangleUpSVG from '../svg/triangle-up.svg';
import WindowsSVG from '../svg/windows.svg';
import MacSVG from '../svg/mac.svg';
import AiMoonSVG from '../svg/aimoon-logo.svg';
import DownloadSVG from '../svg/download.svg';
import EmailSVG from '../svg/email.svg';
import TelegramSVG from '../svg/telegram.svg';
import TwitterSVG from '../svg/twitter.svg';
import IconClose from '../svg/icon-close.svg';
import Cookies from 'js-cookie';
import axios from 'axios';
import copy from 'copy-to-clipboard';

let toastTimer = null;

Modal.setAppElement('#__next');

export default function Home(): JSX.Element {
  const { t } = useTranslation('download');
  const [showSystemNotSupport, setShowSystemNotSupport] = useState(false);
  const [toastKey, setToastKey] = useState('');
  const [showFirstVisit, setShowFirstVisit] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDownload, setShowDownload] = useState(true);
  const [versionInfo, setVersionInfo] = useState({
    mac: {
      version: '',
      publish_time: '',
      url: '',
    },
    win32: {
      version: '',
      publish_time: '',
      url: '',
    },
    win64: {
      version: '',
      publish_time: '',
      url: '',
    },
  });
  const [getVersionError, setGetVersionError] = useState(false);
  const router = useRouter();
  const isEn = router.locale === 'en';

  const oldVersionInfo = {
    win64: {
      version: 'V1.0.9',
      publish_time: '2021/12/16',
      url:
        'https://aimoon-pc.oss-ap-southeast-1.aliyuncs.com/aimoon/win64/aimoon-v1.0.9-x64.exe',
    },
    win32: {
      version: 'V1.0.9',
      publish_time: '2021/12/17',
      url:
        'https://aimoon-pc.oss-ap-southeast-1.aliyuncs.com/aimoon/win32/aimoon-v1.0.9-i32.exe',
    },
    mac: {
      version: 'V1.0.9',
      publish_time: '2021/12/16',
      url:
        'https://aimoon-pc.oss-ap-southeast-1.aliyuncs.com/aimoon/mac/aimoon-v1.0.9.dmg',
    },
  };

  const systemPackage = [
    {
      key: 'win64',
      url: versionInfo.win64.url || '',
      label: isEn ? '64-bit' : '64位',
      text: t('windowSystem'),
      version: versionInfo.win64.version || '',
      publish_time: versionInfo.win64.publish_time || '',
    },
    {
      key: 'win32',
      url: versionInfo.win32.url || '',
      label: isEn ? '32-bit' : '32位',
      text: t('windowSystem'),
      version: versionInfo.win32.version || '',
      publish_time: versionInfo.win32.publish_time || '',
    },
    {
      key: 'mac',
      url: versionInfo.mac.url || '',
      label: '',
      text: t('macSystem'),
      version: versionInfo.mac.version || '',
      publish_time: versionInfo.mac.publish_time || '',
    },
  ];

  const getVersion = async () => {
    try {
      const {
        data: { data, success },
      } = await axios.get('/api/aimoon/version');
      if (success) {
        setGetVersionError(false);
        setVersionInfo(data);
        return;
      }
      setGetVersionError(true);
    } catch (e) {
      setGetVersionError(true);
      console.log(e);
    }
  };

  const Toast = (context: {
    classname: string;
    text: string;
    show: boolean;
  }): JSX.Element => {
    const { classname, text, show } = context;
    return (
      <div
        className={classnames(classname, {
          [styles.show]: show,
        })}
      >
        {text}
      </div>
    );
  };

  // 获取软件版本号
  // useEffect(() => {
  //   getVersion();
  // }, []);

  useEffect(() => {
    // 非首次进入网站隐藏modal
    const firstVisit = JSON.parse(window.localStorage.getItem('firstVisit'));
    if (firstVisit === false) {
      setShowFirstVisit(false);
    }
  }, []);

  useEffect(() => {
    const isMobile = isMobileDevice();
    // mobile、pad设备隐藏下载section
    if (isMobile) {
      setShowDownload(false);
    }
    setShowDownload(true);
  }, []);

  // useEffect(() => {
  //   const closeDropdown = () => {
  //     setShowDropdown(false);
  //   };
  //   // 关闭多语言下拉
  //   document.addEventListener('click', closeDropdown);
  //   return () => {
  //     document.removeEventListener('click', closeDropdown);
  //   };
  // }, []);

  const showToast = (key: string) => {
    setToastKey(key);
    toastTimer = setTimeout(() => {
      setToastKey('');
    }, 1000);
  };

  // 接口挂了的时候展示toast
  // const showDownloadErrorToast = () => {
  //   setShowDownloadError(true);
  //   toastTimer = setTimeout(() => {
  //     setShowDownloadError(false);
  //   }, 1000);
  // };

  // 根据用户系统自动下载对应版本
  const autoDownload = (type: 'new' | 'old') => {
    clearTimeout(toastTimer);

    const isMobile = isMobileDevice();
    if (isMobile) {
      showToast('appNotSupport');
      return;
    }

    const os = getOS();
    if (!os) {
      setShowSystemNotSupport(true);
      // window.alert(t('systemLimit'));
      return;
    }

    if (type === 'old') {
      window.open(oldVersionInfo[os].url);
      return;
    }

    if (!versionInfo[os].url) {
      // showDownloadErrorToast();
      showToast('downloadError');
      return;
    }

    // openUrl({
    //   url: versionInfo[os].url,
    //   target: '_self',
    //   className: 'matomo_download',
    // });
    window.open(versionInfo[os].url);
    trackLink(versionInfo[os].url, 'download');
  };

  const download = (url: string) => {
    clearTimeout(toastTimer);

    if (url) {
      // openUrl({
      //   url,
      //   target: '_self',
      //   className: 'matomo_download',
      // });
      window.open(url);
      trackLink(url, 'download');
      return;
    }

    // showDownloadErrorToast();
    showToast('downloadError');
  };

  const confirm = (e) => {
    e.stopPropagation();
    window.localStorage.setItem('firstVisit', JSON.stringify(false));
    setShowFirstVisit(false);
  };

  const metaInfo = isEn
    ? {
        description:
          'AiMoon is a blockchain data analysis tool,which provides flash market conditions, various data. You can fully explore market opportunities, and monitor asset conditions in real time via AiMoon.',
        title: 'AiMoon - AI to the MOON',
      }
    : {
        description:
          'AiMoon是区块链数据分析工具，支持极速行情、各类数据、特色指标，全面分析市场状况。',
        title: 'AiMoon - AI to the MOON',
      };

  return (
    <div className={styles.main}>
      <SeoHead
        siteName="AiMoon"
        cardType="summary_large_image"
        image="https://aimoon-app.oss-cn-shenzhen.aliyuncs.com/static/aimoon_banner.png"
        pathname="download"
        {...metaInfo}
      />
      <Head>
        <title>{metaInfo.title}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="site"
          content={
            isEn
              ? 'http://www.aimoon.com/en/download'
              : 'http://www.aimoon.com/download'
          }
        />
        <meta name="description" content={metaInfo.description} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <Toast
        classname={styles['download-error']}
        show={toastKey === 'downloadError'}
        text={t('downloadError')}
      />
      {/* <main className={styles.main}>
        <h1 className={styles.title}>{t('download')}</h1>
      </main> */}
      <section className={styles['header-background']}>
        <div className={classnames(styles.container, styles.header)}>
          <LogoSVG className={styles.logo} />
          {/* <div
            className={styles['dropdown-btn']}
            onClick={(e) => {
              e.stopPropagation();
              setShowDropdown(!showDropdown);
            }}
          >
            {isEn ? 'English' : '简体中文'}
            {showDropdown ? (
              <TriangleUpSVG className={styles.triangle} />
            ) : (
              <TriangleDownSVG className={styles.triangle} />
            )}
            {showDropdown ? (
              <div className={styles.dropdown}>
                <ul>
                  {[
                    {
                      locale: 'zh-CN',
                      text: '简体中文',
                    },
                    {
                      locale: 'en',
                      text: 'English',
                    },
                  ].map(({ locale, text }) => {
                    return (
                      <li key={locale}>
                        <Link passHref href={router.pathname} locale={locale}>
                          <a
                            onClick={() => {
                              Cookies.set('NEXT_LOCALE', locale);
                            }}
                          >
                            {text}
                          </a>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : null}
          </div> */}
        </div>
      </section>
      <section
        className={classnames(styles['linear-background'])}
        style={{ flex: 1 }}
      >
        <div className={classnames(styles.container, styles.platform)}>
          <div className={styles['platform-text']}>
            <p
              className={classnames(
                styles['platform-title'],
                animationStyles['fade-in-up'],
                animationStyles['animation-delay-02'],
                animationStyles.animated
              )}
            >
              {t('platformTitle')}
            </p>
            <p
              className={classnames(
                styles['platform-title'],
                animationStyles['fade-in-up'],
                animationStyles['animation-delay-04'],
                animationStyles.animated
              )}
            >
              {t('platformViceTitle')}
            </p>
            <div
              className={classnames(
                styles['platform-download-btn'],
                animationStyles['fade-in-up'],
                animationStyles['animation-delay-08'],
                animationStyles.animated
              )}
              onClick={() =>
                window.open('https://www.aicoin.com/download', '_blank')
              }
            >
              <span className="font-bold">Download AICoin</span>
            </div>
          </div>
          <div className={classnames(styles['platform-image'])} />
          <Toast
            classname={styles['app-not-support']}
            show={toastKey === 'appNotSupport'}
            text={t('appNotSupport')}
          />
        </div>
      </section>
      {showDownload ? (
        <section
          className={classnames(
            'relative',
            styles['white-background'],
            styles['download-section']
          )}
        >
          <div
            id="downloadTip"
            className={classnames(
              'container',
              styles.container,
              styles.download_tip,
              animationStyles['fade-in-up'],
              animationStyles['animation-delay-04'],
              animationStyles.animated
            )}
          >
            <div className={classnames(styles.download_tip_title)}>NOTICE</div>
            <div className={classnames(styles.download_tip_content)}>
              Download of AiMoon PC version has been suspended for service
              adjustment, please go to{' '}
              <span
                className="font-medium"
                onClick={() =>
                  window.open('https://www.aicoin.com/download', '_blank')
                }
              >
                AICoin offical website
              </span>{' '}
              for download if needed.
            </div>
            <div className={classnames(styles.download_tip_time)}>
              <p>March 9, 2022</p>
              <p>AiMoon</p>
            </div>
          </div>
        </section>
      ) : null}
      {/* <section className={styles['linear-background']}>
        <div className={classnames(styles.container, styles.kline)}>
          <div className={styles['kline-image']} />
          <div className={styles['kline-text']}>
            <p
              className={classnames(
                styles['common-title'],
                animationStyles['fade-in-up'],
                animationStyles['animation-delay-02'],
                animationStyles.animated
              )}
            >
              {t('klineTitle')}
            </p>
            <p
              className={classnames(
                styles['common-title'],
                styles.hide,
                animationStyles['fade-in-up'],
                animationStyles['animation-delay-04'],
                animationStyles.animated
              )}
            >
              {t('klineViceTitle')}
            </p>
            <p
              className={classnames(
                styles['common-description'],
                styles['font-opacity'],
                animationStyles['fade-in-up'],
                animationStyles['animation-delay-06'],
                animationStyles.animated
              )}
            >
              {t('klineDescription')}
            </p>
          </div>
        </div>
      </section>
      <section className={styles['white-background']}>
        <div className={classnames(styles.container, styles.signal)}>
          <div className={styles['signal-text']}>
            <p
              className={classnames(
                styles['common-title'],
                styles['blcak-font'],
                animationStyles['fade-in-up'],
                animationStyles['animation-delay-02'],
                animationStyles.animated
              )}
            >
              {t('signalTitle')}
            </p>
            <p
              className={classnames(
                styles['common-title'],
                styles.hide,
                styles['blcak-font'],
                animationStyles['fade-in-up'],
                animationStyles['animation-delay-04'],
                animationStyles.animated
              )}
            >
              {t('signalViceTitle')}
            </p>
            <p
              className={classnames(
                styles['common-description'],
                styles['gray-font'],
                animationStyles['fade-in-up'],
                animationStyles['animation-delay-06'],
                animationStyles.animated
              )}
            >
              {t('signalDescription')}
            </p>
          </div>
          <div className={styles['signal-image']} />
        </div>
      </section>
      <section className={styles['linear-background']}>
        <div className={classnames(styles.container, styles.assets)}>
          <div className={styles['assets-text']}>
            <p
              className={classnames(
                styles['common-title'],
                animationStyles['fade-in-up'],
                animationStyles['animation-delay-02'],
                animationStyles.animated
              )}
            >
              {t('assetsTitle')}
            </p>
            <p
              className={classnames(
                styles['common-title'],
                styles.hide,
                animationStyles['fade-in-up'],
                animationStyles['animation-delay-04'],
                animationStyles.animated
              )}
            >
              {t('assetsViceTitle')}
            </p>
            <p
              className={classnames(
                styles['common-description'],
                styles['font-opacity'],
                animationStyles['fade-in-up'],
                animationStyles['animation-delay-06'],
                animationStyles.animated
              )}
            >
              {t('assetsDescription')}
            </p>
          </div>
          <div className={styles['assets-image']} />
        </div>
      </section> */}
      <section className={styles['footer-background']}>
        <div className={classnames(styles.container, styles.footer)}>
          <div className={styles['footer-text']}>
            <div className={styles['contact-text']}>{t('email')}</div>
            <div className={styles['contact-info']}>
              <div className={styles.email}>
                <a href="mailto:support@aimoon.com">
                  <EmailSVG className={styles['footer-icon']} />
                  <span>support@aimoon.com</span>
                </a>
              </div>
              <div className={styles.telegram}>
                <a
                  href="https://t.me/aimoon_en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TelegramSVG className={styles['footer-icon']} />
                  <span>https://t.me/aimoon_en</span>
                </a>
              </div>
              <div className={styles.twitter}>
                <a
                  href="https://twitter.com/AIMoonGlobal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterSVG className={styles['footer-icon']} />
                  <span>https://twitter.com/AIMoonGlobal</span>
                </a>
              </div>
            </div>
          </div>
          <div className={styles['footer-reserved']}>
            {new Date().getFullYear()} AiMoon All Rights Reserved
          </div>
        </div>
      </section>
      <Modal
        isOpen={showFirstVisit}
        style={{
          // stylelint-disable selector-type-no-unknown
          overlay: {
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            top: '50%',
            right: 'auto',
            bottom: 'auto',
            left: '50%',
            width: '384px',
            padding: '38px 28px',
            marginRight: '-50%',
            fontSize: '14px',
            color: '#181818',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <div className={styles['area-limit-modal']}>
          <div className={styles.title}>
            <AiMoonSVG height={40} />
          </div>
          <p className={styles.user}>{t('modalUser')}</p>
          <p className={styles.text}>{t('modalText')}</p>
          <div className={styles.btn} onClick={(e) => confirm(e)}>
            {t('modalBtn')}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showSystemNotSupport}
        style={{
          overlay: {
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            top: '50%',
            right: 'auto',
            bottom: 'auto',
            left: '50%',
            width: '398px',
            padding: '0 24px',
            marginRight: '-50%',
            fontSize: '14px',
            color: '#141722',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <div className={styles['system-not-support-modal']}>
          <Toast
            classname={styles.copied}
            show={toastKey === 'copied'}
            text={t('copied')}
          />
          <div className={styles.header}>
            <span className={styles.title}>{t('reminder')}</span>
            <IconClose
              width={14}
              height={14}
              className={styles.close}
              onClick={() => {
                setShowSystemNotSupport(false);
              }}
            />
          </div>
          <div className={styles.content}>
            <p className={styles.text}>{t('systemLimit')}</p>
            <p className={styles.text}>
              {t('email')}：
              <a href="mailto:support@aimoon.com">support@aimoon.com</a>
            </p>
          </div>
          <div
            className={styles.btn}
            onClick={() => {
              clearTimeout(toastTimer);
              copy('support@aimoon.com');
              showToast('copied');
            }}
          >
            {t('copy')}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export const getStaticProps: GetStaticProps = async (context) => {
  return {
    props: {
      ...(await serverSideTranslations(context.locale, ['download'])),
    }, // will be passed to the page component as props
  };
};
